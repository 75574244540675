<!--
 * @Descripttion:详情页
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/30 15:01
-->
<template>
  <div class="layout">
    <Header
      :isShowPersonal="true"
      :isShowTab="true"
      :isShowHelp="true"
      @edit="handleEditQuestion"
      @dispatch="handleDispatchQuestion"
      @recycle="handleRecycle"
      @analysis="handleAnalysis"
    />
    <div class="other">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from "@/components/common/QuestionHeader";

export default({
  name: 'Detail',
  components: {
    Header,
  },
  data() {
    return {
      params: {}    
    }
  },
  methods: {
    handleEditQuestion() {
      // 点击跳转路由
      const id = this.$route.params.id
      this.$router.push({
        path: `/detail/${id}/editQuestion`,
      })
    },
    handleDispatchQuestion() {
      // 点击跳转路由
      const id = this.$route.params.id
      this.$router.push({
        path: `/detail/${id}/dispatchQuestion`,
      })
    },
    handleRecycle() {
      // 点击跳转路由
      const id = this.$route.params.id
      this.$router.push({
        path: `/detail/${id}/recycleBin`,
      })
    },
    handleAnalysis() {
      // 点击跳转路由
      const id = this.$route.params.id
      this.$router.push({
        path: `/detail/${id}/analysis`,
      })
    }
  },
  created() {
  }
})
</script>

<style lang="less" scoped>
.layout {
  width: 100%;
  height: 100%;
  background-color: @backgroundColor;
  .other {
    width: 100%;
    height: 100%;
    padding-top: 72px;
    background-color: @backgroundColor;
    overflow-y: scroll;
  }
}
</style>
